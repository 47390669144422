import React from 'react'
import styled from 'styled-components'
import Done from './assets/done.svg'

const UnstyledIcon = ({ className, todo }) => (
    <div className={className} $appearDirection="none" $appearIntersection>
        {!todo && (
            <div>
                <img src={Done} alt="" />
            </div>
        )}
    </div>
)

const Icon = styled(UnstyledIcon)`
    background-color: #ffffff;
    border-radius: 50%;
    border: 1px solid #0324ff;
    box-sizing: border-box;
    height: 32px;
    pointer-events: none;
    position: relative;
    width: 32px;

    div {
        background-color: #0324ff;
        border-radius: 50%;
        height: 100%;
        width: 100%;
    }

    img {
        display: block;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%) scale(0.67);
    }
`

export default Icon
