import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { Medium, MqTablet, MqDesktop } from 'streamr-ui/css/consts'
import Icon from './Icon'

const Content = styled.div`
    display: none;
    flex: 0 1 100%;
    box-shadow: 0px 0px 30px rgb(0 0 0 / 5%);

    @media ${MqDesktop} {
        display: block;
    }
`

const Inner = styled.div`
    background-color: #ffffff;
    border-radius: 4px;
    padding: 24px 32px;
    position: relative;

    ::before {
        background-color: #ffffff;
        content: '';
        height: 10px;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        width: 10px;
    }

    @media ${MqTablet} {
        ::before {
            height: 12px;
            width: 12px;
        }
    }
`

const IconContainer = styled.div`
    flex: 0;
    padding: 0 24px 0 0;

    ${Icon} {
        margin: 0 auto;
    }

    & + ${Content} {
        display: block;
    }

    @media ${MqTablet} {
        padding: 0 48px 0 0;
    }

    @media ${MqDesktop} {
        padding: 0 48px;
    }
`

const Tag = styled.span`
    background-color: #f5f5f5;
    border-radius: 0.125rem;
    font-size: 1em;
    font-weight: ${Medium};
    line-height: 1em;
    margin-left: 0.5rem;
    padding: 0.25rem 0.5rem;
    white-space: nowrap;
`

const UnstyledMilestone = forwardRef(function UnstyledMilestone(
    { index = 0, children, tag, title, todo, ...props },
    ref,
) {
    return (
        <div {...props} ref={ref}>
            <Content />
            <IconContainer>
                <Icon todo={todo} />
            </IconContainer>
            <Content
                $appearIntersection
                $appearDirection={index % 2 === 0 ? 'desktopRightwards' : 'leftwards'}
            >
                <Inner>
                    <h3>
                        {title}
                        {!!tag && (
                            <>
                                {' '}
                                <Tag>{tag}</Tag>
                            </>
                        )}
                    </h3>
                    {children}
                </Inner>
            </Content>
        </div>
    )
})

const Milestone = styled(UnstyledMilestone)`
    align-items: center;
    color: #525252;
    display: flex;

    & + & {
        margin-top: 48px;
    }

    @media ${MqTablet} {
        & + & {
            margin-top: 64px;
        }
    }

    @media ${MqDesktop} {
        & + & {
            margin-top: 120px;
        }

        :nth-child(even) {
            flex-direction: row-reverse;

            ${Inner}::before {
                left: auto;
                right: 0;
                transform: translate(50%, -50%) rotate(45deg);
            }
        }
    }

    h3 {
        font-size: 14px;
        font-weight: ${Medium};
        line-height: normal;
        margin: 0 0 1em;
        color: #0c009a;
    }

    ul {
        font-size: 12px;
        line-height: 24px;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        position: relative;
    }

    li::before {
        color: ${({ todo }) => (todo ? '#0324FF' : '#0EAC1B')};
        content: '• ';
        position: absolute;
        left: -0.75em;
    }

    @media ${MqTablet} {
        h3 {
            font-size: 16px;
            margin: 0 0 0.5em;
        }

        ul {
            font-size: 14px;
        }
    }
`

export default Milestone
