import React from 'react'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import { MqTablet, MqDesktop, Medium } from 'streamr-ui/css/consts'
import icon from './assets/roadmap.png'
import icon2x from './assets/roadmap@2x.png'
import Milestone from './Milestone'
import Track from './Track'
import Layout from '~shared/Layout'
import Container from '~shared/Container'
import Spacer from '~shared/Spacer'
import Display from '~shared/Display'
import ScrollEncourager from '~shared/ScrollEncourager'
import Clip from '~shared/Clip'
import { Headline, Paragraph } from '~/components/DataFund/Hero'

const Body = styled.div`
    background-color: #fcfcfc;
`

const Hero = styled.div`
    color: #0c009a;
    margin: 0 auto;

    strong {
        font-weight: ${Medium};
    }

    ${Clip} {
        margin: 0 auto;
    }

    @media ${MqTablet} {
        max-width: 496px;

        ${Clip} {
            margin: 0;
        }
    }

    @media ${MqDesktop} {
        max-width: 583px;
    }
`

const TimelineContainer = styled(Container)`
    @media ${MqTablet} {
        max-width: 27rem;
    }

    @media ${MqDesktop} {
        max-width: 69rem;
    }
`

const Timeline = styled.div`
    position: relative;
    z-index: 0;

    @media ${MqDesktop} {
        max-width: none;

        ${Track}::before {
            display: none;
        }
    }
`

function P(props) {
    return <Paragraph {...props} $appear />
}

const UnstyledRoadmap = (props) => (
    <Layout {...props} $backgroundColor="white">
        <Container>
            <Hero>
                <Spacer head="36px,138px,124px" $appear>
                    <Display mobile="block" tablet="none">
                        <Clip width={163} height={160} rect={[1, 0.965]}>
                            <img src={icon} srcSet={`${icon2x} 2x`} alt="" />
                        </Clip>
                    </Display>
                    <Display mobile="none" tablet="block">
                        <Clip width={204} height={200} dx={0.035} rect={[0.93, 0.965]}>
                            <img src={icon} srcSet={`${icon2x} 2x`} alt="" />
                        </Clip>
                    </Display>
                    <Headline $appear>Project roadmap</Headline>
                    <ReactMarkdown
                        components={{
                            p: P,
                        }}
                    >
                        Follow the Streamr project&apos;s progress through the various development
                        milestones: **Monk**,&nbsp;**Corea**, **Brubeck** and **Streamr 1.0**.
                        Future milestones are at top, past&nbsp;achievements below. For&nbsp;more
                        details, check out the [blog](https://blog.streamr.network/) or
                        the&nbsp;[Trello board](https://trello.com/b/tSRNX6YN/streamr-tatum).
                    </ReactMarkdown>
                </Spacer>
            </Hero>
        </Container>
        <Spacer head="128px,160px,108px">
            <Display mobile="none" desktop="block">
                <ScrollEncourager center />
                <Spacer head=",,160px" pad />
            </Display>
        </Spacer>
        <Body $appearIntersection>
            <TimelineContainer>
                <Timeline>
                    <Track />
                    <div id={ScrollEncourager.TargetID} />
                    <Spacer head="96px,108px,174px" tail="156px,156px,236px" pad>
                        <Milestone index={0} title="H2/2023" tag="Streamr 1.0" todo>
                            <ul>
                                <li>DATA tokenomics fully deployed</li>
                                <li>Sponsors create Sponsorships to incentivise Operators</li>
                                <li>Delegators stake on Operators to share revenue</li>
                                <li>Network goes trackerless</li>
                            </ul>
                        </Milestone>
                        <Milestone index={1} title="H1/2023">
                            <ul>
                                <li>Streamr Hub</li>
                                <li>Gasless (metatransaction) support</li>
                                <li>Decentralized encryption key storage</li>
                            </ul>
                        </Milestone>
                        <Milestone index={2} title="H2/2022">
                            <ul>
                                <li>Non-custodial mining</li>
                                <li>Multichain Marketplace</li>
                                <li>Data Unions 3.0</li>
                                <li>Faster and more scalable key exchange</li>
                            </ul>
                        </Milestone>
                        <Milestone index={3} title="H1/2022" tag="Brubeck">
                            <ul>
                                <li>Network is decentralized!</li>
                                <li>Anyone can run nodes</li>
                                <li>Streamr SDK and Streamr nodes</li>
                                <li>Node Operators can earn DATA by running Streamr nodes</li>
                                <li>End-to-end encryption</li>
                                <li>Automatic encryption key exchange</li>
                            </ul>
                        </Milestone>
                        <Milestone index={4} title="2020" tag="Corea">
                            <ul>
                                <li>P2P architecture finalized, all nodes still run by Streamr</li>
                                <li>Performance testing, metrics collection, and comparisons</li>
                                <li>Random network topology per stream controlled by trackers</li>
                                <li>
                                    Stream partitioning &amp; stream-level data retention period
                                </li>
                                <li>Data Unions framework launched</li>
                            </ul>
                        </Milestone>
                        <Milestone index={5} title="2019" tag="Monk">
                            <ul>
                                <li>Ethereum key-based identity and authentication</li>
                                <li>Tamper-proof data with data signing &amp; message chaining</li>
                                <li>Protocol improvements preparing for P2P Network release</li>
                            </ul>
                        </Milestone>
                        <Milestone index={6} title="2018">
                            <ul>
                                <li>Marketplace launch</li>
                                <li>Stability and scalability improvements</li>
                                <li>R&amp;D work started for P2P milestone</li>
                            </ul>
                        </Milestone>
                        <Milestone index={7} title="2017" tag="Launch">
                            <ul>
                                <li>Scalable centralized pub/sub infrastructure</li>
                                <li>JavaScript client library</li>
                                <li>Core UI for managing Streamr resources</li>
                            </ul>
                        </Milestone>
                    </Spacer>
                </Timeline>
            </TimelineContainer>
            <Spacer tail="100px,132px,365px" pad />
        </Body>
    </Layout>
)

const Roadmap = styled(UnstyledRoadmap)`
    color: #0c009a;
    border-bottom: 1px solid #cdcdcd;

    ${ScrollEncourager} path {
        fill: #0324ff;
    }
`

export default Roadmap
