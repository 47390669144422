import React from 'react'
import Roadmap from '../components/Roadmap'
import page from '~utils/page'
import { RESOURCES } from '~shared/Nav'
import Head from '~shared/Head'

// eslint-disable-next-line import/no-unused-modules
export default page(Roadmap, {
    highlight: RESOURCES,
    headComponent: (
        <Head
            mediaDescription="All project milestones"
            description="Follow the Streamr project's progress through the various development milestones; Monk, Corea, Brubeck and Tatum."
        />
    ),
})
